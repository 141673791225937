import type * as i from 'types';
import * as React from 'react';

import { useInterval } from 'services/hooks';
import { ProgressRing } from 'common/interaction';

import { SlideThumbnailWrapper, SlideThumbnailImage } from './styled';

function isFocalPointSlide(
  slide: i.TypeSlideshowItem | i.TypeImageWithFocalPoint,
): slide is i.TypeImageWithFocalPoint {
  return 'focalPoint' in slide.fields;
}

export const SlideThumbnail: React.FC<SlideThumbnailProps> = ({
  slide,
  index,
  currentStory,
  setCurrentStory,
  delay,
}) => {
  const [progress, setProgress] = React.useState(0);
  const isVideo = isFocalPointSlide(slide)
    ? false
    : slide.fields.media?.fields.file.contentType.includes('video');

  const image = (() => {
    if (isFocalPointSlide(slide)) {
      return {
        src: slide.fields.image.fields.file.url,
        alt: slide.fields.image.fields.title,
      };
    }

    if (isVideo) {
      return {
        src: slide.fields.videoThumbnail?.fields.file.url,
        alt: slide.fields.videoThumbnail?.fields.title,
      };
    }

    return {
      src: slide.fields.image?.fields.image.fields.file.url,
      alt: slide.fields.image?.fields.image.fields.title,
    };
  })();

  React.useEffect(() => {
    if (index === currentStory) {
      setProgress(0);
    }
  }, [currentStory]);

  useInterval(() => {
    if (currentStory === index) {
      setProgress(progress + 100 / (delay / 100));
    }
  }, 100);

  return (
    <SlideThumbnailWrapper onClick={() => setCurrentStory(index)}>
      <SlideThumbnailImage src={`${image.src}?w=100`} alt={image.alt} />
      {currentStory === index && <ProgressRing stroke={1.5} progress={progress} />}
    </SlideThumbnailWrapper>
  );
};

type SlideThumbnailProps = {
  slide: i.TypeSlideshowItem | i.TypeImageWithFocalPoint;
  index: number;
  currentStory: number;
  delay: number;
  setCurrentStory: (storyIndex: number) => void;
};
