import type * as i from 'types';
import * as React from 'react';
import { Entry } from 'contentful';

import { FocalPointImage } from 'common/layout';
import { Headline1, Headline4 } from 'common/typography';
import { ImageBlock } from 'modules/blocks';

import {
  ExtendedBlock,
  ContentContainer,
  StyledHeadline,
  TopText,
  ContentWrapper,
  BackgroundBlur,
} from './styled';

export const HeaderImageTop: React.FC<Props> = ({ header }) => {
  const hasSubtitles = Boolean(header.fields.subtitle1 || header.fields.subtitle2);

  if (hasSubtitles) {
    return (
      <ExtendedBlock $hasSubtitles>
        <ImageBlock image={header.fields.image?.fields} fullHeight={false} />
        <ContentWrapper>
          <BackgroundBlur />
          <ContentContainer $hasSubtitles>
            {header.fields.subtitle1 && <TopText>{header.fields.subtitle1}</TopText>}
            <Headline1 size="xl">{header.fields.title}</Headline1>
            {header.fields.subtitle2 && <Headline4>{header.fields.subtitle2}</Headline4>}
          </ContentContainer>
        </ContentWrapper>
      </ExtendedBlock>
    );
  }

  return (
    <ExtendedBlock>
      <ContentContainer>
        <FocalPointImage image={header.fields.image?.fields} />
        <StyledHeadline size="xl">{header.fields.title}</StyledHeadline>
      </ContentContainer>
    </ExtendedBlock>
  );
};

type Props = {
  header: Entry<i.TypeComponentVideoHeaderFields>;
};
