import styled from 'styled-components';

export const SlideThumbnailWrapper = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    transform: rotate(-90deg);
  }
`;

export const SlideThumbnailImage = styled.img`
  width: 39px;
  height: 39px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
`;
