import styled from 'styled-components';

import { media } from 'styles/utils';
import { FocalPointImage } from 'common/layout';

export const BlogRichText = styled.div`
  font-family: ${({ theme }) => theme.fonts.recklessNeue};

  & > p {
    font-family: ${(props) => props.theme.fonts.favoritPro};
    font-size: 1.0625rem;
    line-height: 1.75rem;
    color: ${(props) => props.theme.colors.text};
    letter-spacing: -0.01em;
    font-weight: 200;

    & > a {
      font-family: ${(props) => props.theme.fonts.favoritPro};
      color: ${(props) => props.theme.colors.text};
    }

    ${media.desktop`
      font-size: 1.25rem;
    `}
  }

  & > h1 {
    font-size: 1.75rem;
    line-height: 2.125rem;

    ${media.desktop`
      font-size: 2.67em;
      line-height: 1.45em;
    `}
  }

  & > h2 {
    font-family: ${(props) => props.theme.fonts.favoritPro};
    color: ${(props) => props.theme.colors.text};
    font-size: 1.375rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
    margin-top: 30px;
    margin-bottom: 30px;

    ${media.desktop`
      font-size: 1.5em;
      line-height: 1.33em;
    `}
  }

  & > h3 {
    font-family: ${(props) => props.theme.fonts.favoritPro};
    color: ${(props) => props.theme.colors.text};
    font-size: 1.25rem;
    line-height: 1.5625rem;
    letter-spacing: -0.02em;
    margin-top: 30px;
    margin-bottom: 30px;

    ${media.desktop`
      font-size: 1.33em;
      font-size: 1.5em;
    `}
  }

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    font-weight: 200;
  }

  & > h4,
  & > h5,
  & > h6 {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: normal;
  }

  & > ul,
  ol {
    font-weight: 200;
    font-family: ${(props) => props.theme.fonts.favoritPro};
    font-size: 1.0625rem;
    line-height: 1.375rem;

    & > li {
      font-family: ${(props) => props.theme.fonts.favoritPro};
      font-weight: 200;
      margin-bottom: 30px;
    }
  }

  & > ol {
    padding-left: 1.5em;
  }

  & > ul {
    padding: 0;
    margin: 0;
  }

  & > ul > li {
    list-style-type: none;
    padding-left: 1em;
    text-indent: -0.7em;

    &:before {
      content: '• ';
      list-style-type: circle;
      display: inline-block;
      width: 16px;
      color: ${(props) => props.theme.colors.purple};
    }

    > p {
      display: inline;
      margin: 0;
    }
  }
`;

export const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
  margin: 14px 0;
  font-size: 14px;
  line-height: 125%;
  letter-spacing: -0.01em;
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.favoritPro};

  span {
    opacity: 0.6;
  }

  ${media.desktop`
    padding: 0 50px;
  `}

  ${media.large`
    max-width: 75%;
    margin: 14px auto;
  `}
`;

export const AuthorImage = styled(FocalPointImage)`
  margin-left: 15px;
  margin-right: 8px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  object-fit: cover;
`;
