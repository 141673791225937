import * as React from 'react';

import { isServer } from 'services';

import { Container, StyledRocycleLogo } from './styled';

export const VideoIntro: React.FC<Props> = ({ showIntro, setShowIntro }) => {
  const hasAnimated = isServer ? false : sessionStorage.getItem('introHasBeenShown');

  React.useEffect(() => {
    if (isServer) return;

    if (hasAnimated) {
      setShowIntro(false);
    } else {
      sessionStorage.setItem('introHasBeenShown', 'true');

      const timeoutId = setTimeout(() => {
        setShowIntro(false);
      }, 1500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  return (
    <Container
      $isHidden={!showIntro}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <StyledRocycleLogo />
    </Container>
  );
};

type Props = {
  showIntro: boolean;
  setShowIntro: React.Dispatch<React.SetStateAction<boolean>>;
};
