import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Headline1, Headline3, Headline4 } from 'common/typography';

export const ExtendedBlock = styled.div<ExtendedBlockProps>`
  position: relative;

  ${({ $hasSubtitles, theme }) =>
    !$hasSubtitles &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: ${theme.colors.text};
      overflow: hidden;
    `}

  ${({ $hasSubtitles }) =>
    $hasSubtitles &&
    css`
      height: 90vh;
    `}
`;

type ExtendedBlockProps = {
  $hasSubtitles?: boolean;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  position: relative;

  ${({ $hasSubtitles }) =>
    !$hasSubtitles &&
    css`
      width: 100vw;
      height: 93.5vh;
    `}

  ${({ $hasSubtitles }) =>
    $hasSubtitles &&
    css`
      z-index: 1;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    `}

  ${Headline1},
  ${Headline3},
  ${Headline4} {
    color: ${({ theme }) => theme.colors.white};
    margin: 2px 0;
    text-align: center;
  }
`;

type ContentContainerProps = {
  $hasSubtitles?: boolean;
};

export const StyledHeadline = styled(Headline1)`
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    left: 50px;
    right: auto;
    text-align: left;
  `}
`;

export const TopText = styled(Headline3)`
  font-size: 7vw;

  ${media.desktop`
    font-size: 2vw;
  `}
`;

export const ContentWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 0;
  left: 0;

  ${media.desktop`
    bottom: 60px;
  `}
`;

export const BackgroundBlur = styled.div`
  position: absolute;
  width: 60%;
  height: 100%;
  left: 20%;
  bottom: 0;
  z-index: 0;
  background: ${({ theme }) => theme.colors.text};
  opacity: 0.5;
  filter: blur(110px);
`;
