import type * as i from 'types';
import * as React from 'react';

import { useWithComponentAnimation } from 'hooks';
import ArrowRightIcon from 'vectors/internal/arrow-right.svg';

import {
  ExtendedBlock,
  ExtendedHeading,
  Container,
  RelatedImage,
  RelatedPagesHeader,
  PageBlock,
  ExtendedStyledLink,
} from './styled';

const CallToActionList: React.FC<Props> = ({ title, withAnimation, body }) => {
  const { imageAnimation } = useWithComponentAnimation({
    image: {
      enabled: withAnimation,
      duration: 0.6,
      initialY: 80,
    },
  });

  return (
    <ExtendedBlock>
      <RelatedPagesHeader>
        <ExtendedHeading>{title}</ExtendedHeading>
      </RelatedPagesHeader>
      <Container {...(imageAnimation.enabled && imageAnimation.props)}>
        {body.map((item) => {
          return (
            <PageBlock key={item.sys.id}>
              <RelatedImage
                image={item.fields.image.fields}
                alt={item.fields.image.fields.image.fields.title}
                sizes="(max-width: 768px) 768px, 1024px"
              />
              <ExtendedStyledLink href={item.fields.url || '/'}>
                {item.fields.callToActionText}
                <ArrowRightIcon width={24} height={24} />
              </ExtendedStyledLink>
            </PageBlock>
          );
        })}
      </Container>
    </ExtendedBlock>
  );
};

export type Props = i.TypeComponentCallToActionListFields;

export default CallToActionList;
