import type * as i from 'types';
import * as React from 'react';

import { ContentBlockContainer } from 'common/layout';
import { RichText } from 'common/typography';

import Author from './Author';
import { BlogRichText } from './styled';

const BlogPost: React.FC<Props> = ({ author, ...props }) => {
  return (
    <>
      {author && <Author author={author.fields} />}
      <ContentBlockContainer>
        <RichText body={props.body}>{(body) => <BlogRichText>{body}</BlogRichText>}</RichText>
      </ContentBlockContainer>
    </>
  );
};

export type Props = i.TypeComponentBlogFields;

export default BlogPost;
