import type * as i from 'types';
import * as React from 'react';
import { AnimatePresence } from 'framer-motion';

import { useStoryCarousel } from 'services/hooks';
import { Slide } from 'modules/Slide';
import { SlideThumbnail } from 'modules/SlideThumbnail';

import { SlideThumbnailContainer, ExtendedBlock } from './styled';

export const CallToActionSlideshow: React.FC<Props> = ({ slides }) => {
  const storyDelay = 10000;
  const { currentStory, clickStoryThumbnail } = useStoryCarousel(slides.length - 1, storyDelay);

  if (slides.length === 0) return null;

  return (
    <ExtendedBlock overflow="hidden">
      <SlideThumbnailContainer>
        {slides.map((slide, i) => (
          <SlideThumbnail
            key={slide.sys.id}
            slide={slide}
            index={i}
            delay={storyDelay}
            currentStory={currentStory}
            setCurrentStory={clickStoryThumbnail}
          />
        ))}
      </SlideThumbnailContainer>
      <AnimatePresence initial={false}>
        <Slide
          key={slides[currentStory].sys.id}
          data={slides[currentStory]}
          priority={currentStory === 0}
        />
      </AnimatePresence>
    </ExtendedBlock>
  );
};

type Props = i.TypeCtaBlockSlideshowFields;
