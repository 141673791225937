import styled from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';
import { Block } from 'common/layout';
import { Text } from 'common/typography';
import { ImageBlock } from 'modules/blocks/ImageBlock';

export const ExtendedImageBlock = styled(ImageBlock)`
  height: 100vh;
  margin-bottom: -1vh;
  pointer-events: none;
  width: 100%;
`;

export const ExtendedBlock = styled(Block)`
  position: relative;
  height: 100%;
  text-align: center;
`;

export const TextBlock = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0px 30px;
  max-width: 360px;
  z-index: 1;
  text-align: center;

  ${media.desktop`
    max-width: 560px;
  `}
`;

export const ExtendedText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.recklessNeue};
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
  line-height: 125%;
  margin-bottom: 33px;
  letter-spacing: -0.02em;

  ${media.desktop`
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -0.03em;
  `}
`;

export const ExtendedStyledLink = styled(StyledLink)`
  font-family: ${({ theme }) => theme.fonts.favoritPro};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    align-self: flex-end;
  `}
`;
