import styled from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';
import { Block, FocalPointImage } from 'common/layout';
import { Heading } from 'common/typography';

export const RecruitmentBlockImage = styled(FocalPointImage)`
  height: 28.5vh;
  width: 95vw;
  align-self: flex-start;
  object-fit: cover;

  ${media.desktop`
    width: 45%;
    height: 65vh;
    flex-grow: 1;
    margin-right: 5vw;
    align-self: initial;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  padding-right: 15px;
  padding-left: 15px;
  align-self: flex-end;

  ${media.desktop`
    align-self: center;
  `}
`;

export const ExtendedBlock = styled(Block)<ExtendedBlockProps>`
  background: ${({ theme, $transparentBackground }) =>
    $transparentBackground ? 'transparent' : theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.desktop`
    align-items: center;
    flex-direction: row;
    padding-right: 171px;
  `}
`;

type ExtendedBlockProps = {
  $transparentBackground?: boolean;
};

export const CallToActionLink = styled(StyledLink)`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  align-self: flex-end;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black};
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    font-size: 18px;
  `}
`;

export const ExtendedHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.jvSignature};
  margin: 0;
  font-size: 14.5vw;
  line-height: 1;
  left: 13px;
  white-space: nowrap;
  letter-spacing: -0.02em;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  ${media.desktop`
    font-size: 6.5vw;
    align-self: end;
  `}
`;

export const HeadingSpan = styled.span`
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  text-transform: uppercase;
  font-size: 22.5vw;
  text-indent: 60px;

  ${media.desktop`
    font-size: 10.5vw;
    text-indent: 7.5vw;
  `}
`;
