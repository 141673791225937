import styled from 'styled-components';

import { media } from 'styles/utils';
import { Block } from 'common/layout';

export const ExtendedBlock = styled(Block)`
  background-color: ${({ theme }) => theme.colors.black};
`;

export const SlideThumbnailContainer = styled.div`
  position: absolute;
  top: 51px;
  left: 20px;
  z-index: 2;
  display: flex;

  > :not(:first-child) {
    margin-left: 13px;
  }

  ${media.desktop`
    top: 61px;
    left: 42px;

    > :not(:first-child) {
      margin-left: 10px;
    }
  `}
`;
