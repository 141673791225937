import styled from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';
import { FocalPointImage } from 'common/layout';
import { Text, Heading } from 'common/typography';

export const ExtendedBlock = styled.div<ExtendedBlockProps>`
  position: relative;
  padding: 70px 20px 390px 20px;
  width: 100%;
  height: 100%;
  background: ${({ theme, $transparentBackground }) =>
    $transparentBackground ? 'transparent' : theme.colors.white};
  overflow: hidden;

  ${media.desktop`
    height: 100vh;
    padding: 124px 167px;
    display: flex;
  `}
`;

type ExtendedBlockProps = {
  $transparentBackground?: boolean;
};

export const CallToActionLink = styled(StyledLink)`
  font-size: 14px;
  position: absolute;
  right: 20px;
  bottom: 100px;
  z-index: 2;
  text-align: right;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    bottom: 73px;
    right: 47px;
  `}
`;

export const ExtendedText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.recklessNeue};
  font-size: 16px;
  line-height: 110%;
  position: absolute;
  bottom: 144px;
  right: 20px;
  text-align: left;
  width: 57vw;
  max-width: 480px;

  ${media.desktop`
    width: 27vw;
    bottom: 127px;
    right: 49px;
  `}
`;

export const ExtendedHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.jvSignature};
  margin: 0;
  transform: rotate(-6.72deg);
  font-size: 14.5vw;
  bottom: 227px;
  line-height: 2;
  left: 13px;
  position: absolute;
  white-space: nowrap;
  letter-spacing: -0.02em;
  font-weight: 500;

  ${media.desktop`
    font-size: 9.5vw;
    left: auto;
    bottom: auto;
    top: 197px;
    right: -18px;
  `}
`;

export const ExtendedImage = styled(FocalPointImage)`
  max-height: 100%;
  width: 100%;
  object-fit: cover;

  ${media.desktop`
    width: 35%;
  `}
`;
