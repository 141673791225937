import type * as i from 'types';

import BlogPost from './BlogPost';
import CallToActionFullscreen from './CallToActionFullscreen';
import { CallToActionFullscreenZoomin } from './CallToActionFullscreenZoomin';
import CallToActionList from './CallToActionList';
import { CallToActionSlideshow } from './CallToActionSlideshow';
import CallToActionText from './CallToActionText';
import CallToActionTextRight from './CallToActionTextRight';
import CallToActionTextRightLarge from './CallToActionTextRightLarge';
import LinkWithEmoji from './LinkWithEmoji';

export const ComponentsFactory = (
  component: NonNullable<i.TypePageGeneralFields['body']>[number],
  options?: {
    withVideoHeader: boolean;
  },
) => {
  switch (component.fields.type) {
    case 'cta_image':
      if (component.fields.body.fields.type === 'cta_layout_fullscreen') {
        if (component.fields.body.fields.animation === 'zoom-in') {
          return (
            <CallToActionFullscreenZoomin
              key={component.sys.id}
              {...(component.fields as i.CTAComponentLayoutFullscreen)}
            />
          );
        }

        return (
          <CallToActionFullscreen
            key={component.sys.id}
            {...(component.fields as i.CTAComponentLayoutFullscreen)}
          />
        );
      }

      if (component.fields.body.fields.type === 'cta_layout_right') {
        if (component.fields.body.fields.variant === 'text-spaced') {
          return (
            <CallToActionTextRight
              key={component.sys.id}
              transparentBackground={!options?.withVideoHeader}
              {...(component.fields as i.CTAComponentLayoutRight)}
            />
          );
        }

        if (component.fields.body.fields.variant === 'text-together') {
          return (
            <CallToActionTextRightLarge
              key={component.sys.id}
              transparentBackground={!options?.withVideoHeader}
              {...(component.fields as i.CTAComponentLayoutRight)}
            />
          );
        }

        throw new Error(
          `'${component.fields.type}' component variant '${component.fields.body.fields.variant}' has no implementation!`,
        );
      }
      break;
    case 'cta_list':
      return <CallToActionList key={component.sys.id} {...component.fields} />;
    case 'cta_slideshow':
      return <CallToActionSlideshow key={component.sys.id} {...component.fields} />;
    case 'cta_text':
      return <CallToActionText key={component.sys.id} {...component.fields} />;
    case 'blog_layout':
      return <BlogPost key={component.sys.id} {...component.fields} />;
    case 'link_with_emoji':
      return <LinkWithEmoji key={component.sys.id} {...component.fields} />;
    default:
      throw new Error(`Invalid Contentful component ${JSON.stringify(component.fields, null, 2)}`);
  }

  return null;
};
