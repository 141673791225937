import * as React from 'react';

import { GeneralPageProps } from 'src/pages/[[...slug]]';
import { Meta } from 'common/general';
import { ComponentsFactory } from 'modules/contentful/factory';

import { PageWithImageSideHeader } from './PageWithImageSideHeader';
import { PageWithImageTopHeader } from './PageWithImageTopHeader';
import { PageWithVideoHeader } from './PageWithVideoHeader';
import { PageWithoutHeader } from './PageWithoutHeader';

const Page: React.FC<Props> = ({ pageData, headerLocations, navigation, children }) => {
  if (!pageData) return null;

  const components = pageData.fields.body;

  return (
    <>
      <Meta
        pageName={pageData.fields.title}
        meta={pageData.fields.seo?.fields}
        // Landing pages are articles
        article={pageData.fields.type === 'landing'}
      />

      {(() => {
        switch (pageData.fields.fixedHeader?.fields.variant) {
          case 'video-top':
            return (
              <PageWithVideoHeader
                {...{ headerLocations, navigation }}
                videoHeader={pageData.fields.fixedHeader}
              >
                {components?.map((component) =>
                  ComponentsFactory(component, { withVideoHeader: true }),
                )}
                {children}
              </PageWithVideoHeader>
            );
          case 'image-side':
            return (
              <PageWithImageSideHeader
                {...{ headerLocations, navigation }}
                headerImage={pageData.fields.fixedHeader.fields.image?.fields}
                isFixedLayout={pageData.fields.fixedLayout}
                title={pageData.fields.title}
              >
                {components?.map((component) => ComponentsFactory(component))}
                {children}
              </PageWithImageSideHeader>
            );
          case 'image-top':
            return (
              <PageWithImageTopHeader
                {...{ headerLocations, navigation }}
                imageHeader={pageData.fields.fixedHeader}
              >
                {components?.map((component) => ComponentsFactory(component))}
                {children}
              </PageWithImageTopHeader>
            );
          default:
            return (
              <PageWithoutHeader {...{ headerLocations, navigation }}>
                {components?.map((component) => ComponentsFactory(component))}
                {children}
              </PageWithoutHeader>
            );
        }
      })()}
    </>
  );
};

export type Props = GeneralPageProps & {
  children?: React.ReactNode;
};

export default Page;
