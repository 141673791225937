import styled from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';
import { FocalPointImage } from 'common/layout';
import { Heading, Text } from 'common/typography';

export const ExtendedStyledLink = styled(StyledLink)`
  font-size: 14px;
  position: absolute;
  right: 20px;
  bottom: 40px;
  z-index: 2;
  text-align: right;
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    right: 50px;
    bottom: 60px;
  `}
`;

export const TextContainer = styled.div`
  position: absolute;
  left: 15px;
  bottom: 82px;

  ${media.desktop`
    left: 44px;
    bottom: 60px;
  `}
`;

export const ExtendedHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.bigShouldersDisplay};
  font-size: 28vw;
  text-transform: uppercase;
  line-height: 80%;
  letter-spacing: -0.02em;
  font-weight: 400;

  ${media.desktop`
    font-size: 14vw;
  `}
`;

export const ExtendedText = styled(Text)`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${(props) => props.theme.colors.white};
  font-size: 4vw;
  line-height: 100%;
  letter-spacing: -0.03em;
  margin-bottom: 22px;

  ${media.desktop`
    font-size: 1.5vw;
    margin-bottom: 20px;
  `}
`;

export const Image = styled(FocalPointImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
