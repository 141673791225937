import type * as i from 'types';
import * as React from 'react';

import { useIntersection, usePageBackground } from 'hooks';
import { Block } from 'common/layout';

import { ExtendedImageBlock, ExtendedStyledLink, ExtendedText, TextBlock } from './styled';

const CallToActionFullscreen: React.FC<Props> = ({ title, url, callToActionText, ...props }) => {
  const { changeOverlayOpacity } = usePageBackground();
  const body = props.body.fields;
  const ref = React.useRef<HTMLDivElement>(null);
  const isIntersecting = useIntersection(ref, 0.7);

  React.useEffect(() => {
    if (body.animation === 'dark-overlay') {
      changeOverlayOpacity();
    }

    return () => {
      changeOverlayOpacity(0);
    };
  }, [body.animation]);

  React.useEffect(() => {
    if (body.animation === 'dark-overlay') {
      changeOverlayOpacity(isIntersecting ? 0.35 : 0);
    }
  }, [isIntersecting, body.animation]);

  return (
    <Block ref={ref}>
      <ExtendedImageBlock image={body.image.fields} />
      <TextBlock>
        <ExtendedText>{title}</ExtendedText>
        <ExtendedStyledLink href={url || '/'}>{callToActionText} -&gt;</ExtendedStyledLink>
      </TextBlock>
    </Block>
  );
};

export type Props = i.CTAComponentLayoutFullscreen;

export default CallToActionFullscreen;
