import type * as i from 'types';
import * as React from 'react';

import { useDevice, useIntersection } from 'services/hooks';

import {
  MaskedImage,
  ExtendedBlock,
  TextContainer,
  ExtendedHeading,
  ExtendedText,
  CallToActionLink,
} from './styled';

export const CallToActionFullscreenZoomin: React.FC<Props> = ({
  callToActionText,
  url,
  ...props
}) => {
  const body = props.body.fields;
  const { isMobile } = useDevice();
  const imagePadding = isMobile ? '0' : '150px 37%';

  const containerRef = React.useRef<HTMLDivElement>(null);
  const textRef = React.useRef<HTMLDivElement>(null);
  const shouldAnimate = useIntersection(containerRef, 0.3);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!textRef.current) return;

    const positionX = event.clientX;
    const positionY = event.clientY;

    const dimensionScaleX = positionX / event.currentTarget.clientWidth;
    const dimensionScaleY = positionY / event.currentTarget.clientHeight;

    textRef.current.style.fontVariationSettings = `"wght" ${((700 - 100) * dimensionScaleX).toFixed(
      2,
    )}, "ital" ${((12 - 1) * dimensionScaleY).toFixed(2)}`;
  };

  return (
    <ExtendedBlock
      ref={containerRef}
      $overlayOpacity={shouldAnimate && !isMobile ? 0.4 : 0}
      onMouseMove={handleMouseMove}
    >
      <MaskedImage
        image={body.image.fields}
        alt={body.image.fields.image.fields.title}
        animate={{ padding: shouldAnimate ? '0px' : imagePadding }}
        initial={{ padding: imagePadding }}
        transition={{ duration: 0.8 }}
        withAnimation
      />
      <TextContainer>
        <ExtendedHeading as="h2">{body.title}</ExtendedHeading>
        {body.subtitle && <ExtendedText ref={textRef}>{body.subtitle}</ExtendedText>}
        <CallToActionLink href={url}>{callToActionText} -&gt;</CallToActionLink>
      </TextContainer>
    </ExtendedBlock>
  );
};

type Props = i.CTAComponentLayoutFullscreen;
