import type * as i from 'types';
import * as React from 'react';

import { Video } from 'common/interaction';
import { SlideTransition } from 'common/layout';

import { ExtendedStyledLink, ExtendedHeading, ExtendedText, TextContainer, Image } from './styled';

export const Slide: React.FC<StoryProps> = ({ data, priority }) => {
  const { media, image, title, subtitle, callToActionText } = data.fields;

  const slide = image || media;
  const isVideo = !image && media?.fields.file.contentType.includes('video');

  const imageProps = {
    loading: priority ? 'eager' : 'lazy',
    fetchPriority: priority ? 'high' : 'low',
    decoding: priority ? 'auto' : 'async',
  } satisfies ImageProps;

  return (
    <SlideTransition>
      {slide && (
        <>
          {isVideo ? (
            <Video autoPlay loop muted playsInline>
              <source src={media?.fields.file.url} type="video/mp4" />
            </Video>
          ) : (
            <Image image={image?.fields} {...imageProps} />
          )}
        </>
      )}
      <TextContainer>
        <ExtendedText>{subtitle}</ExtendedText>
        <ExtendedHeading color="white" margin="0">
          {title}
        </ExtendedHeading>
      </TextContainer>
      {callToActionText &&
        (data.fields.url ? (
          <ExtendedStyledLink href={data.fields.url}>{callToActionText} -&gt;</ExtendedStyledLink>
        ) : (
          <>{callToActionText} -&gt;</>
        ))}
    </SlideTransition>
  );
};

type StoryProps = {
  data: i.TypeSlideshowItem;
  priority: boolean;
};

// fetch-priority is a fairly new attribute and is not added to the types yet
type ImageProps = Partial<React.ComponentProps<'img'> & { fetchPriority: string }>;
