import { motion } from 'framer-motion';
import styled from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';
import { Block, FocalPointImage } from 'common/layout';
import { Text } from 'common/typography';

export const ExtendedBlock = styled(Block)`
  background: transparent;
  padding-top: 152px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  height: 100%;
`;

export const ExtendedStyledLink = styled(StyledLink)`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${(props) => props.theme.colors.black};
  margin-top: 10px;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: fit-content;
  align-items: center;
`;

export const RelatedPagesHeader = styled.div`
  ${media.desktop`
    display: grid;
    grid-template-columns: 20% auto 25%;
    grid-gap: 15px;
  `}
`;

export const ExtendedHeading = styled(Text)`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  font-size: 24px;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 30px;
  grid-column: 2;
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;

  ${media.desktop`
    display: grid;
    grid-template-columns: 20% auto 25%;
    grid-gap: 15px;
  `}
`;

export const RelatedImage = styled(FocalPointImage)`
  max-width: 100%;
  object-fit: cover;
  width: 100vw;
  height: calc(100vw - 100px);
`;

export const PageBlock = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${media.desktop`
    &:nth-child(1) > img{
      height: 57.12%;
    }

    &:nth-child(2) > img {
      height: 100%;
    }

    &:nth-child(3) > img {
      height: 80%;
    }
  `}
`;
