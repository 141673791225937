import type * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { AuthorContainer, AuthorImage } from './styled';

const Author: React.FC<Props> = ({ author }) => {
  const { t } = useTranslation();

  return (
    <AuthorContainer>
      <span>{t('Words by')}</span>
      <AuthorImage image={author.image.fields} sizes="50px" />
      &nbsp;{author.name}
    </AuthorContainer>
  );
};

export type Props = {
  author: i.TypeAuthorFields;
};

export default Author;
