import type * as i from 'types';
import * as React from 'react';

import { FooterMenu, HeaderMenu } from 'common/layout';
import { BlurredCirclesBlock } from 'modules/blocks';

export const PageWithoutHeader: React.FC<Props> = ({ children, navigation, headerLocations }) => {
  return (
    <>
      <HeaderMenu
        menuItems={navigation.fields.headerNavigationItems}
        headerLocations={headerLocations}
      />
      {children}
      <BlurredCirclesBlock>
        <FooterMenu navigation={navigation.fields} />
      </BlurredCirclesBlock>
    </>
  );
};

type Props = {
  children: React.ReactNode;
  headerLocations: i.HeaderLocation[];
  navigation: i.TypeNavigationList;
};
