import styled from 'styled-components';

import theme from 'styles/theme';
import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';
import { Block } from 'common/layout';
import { Heading, RichText } from 'common/typography';

const switchBackgroundGradient = (variant: number) => {
  switch (variant) {
    case 1:
      return `linear-gradient(135.39deg, ${theme.colors.beige.sand} 1.32%, ${theme.colors.white.floral} 98.29%)`;
    case 2:
      return `linear-gradient(180deg, ${theme.colors.gray.pastel} 0%, ${theme.colors.white.azureish} 100%)`;
    case 3:
      return theme.colors.beige.dark;
    case 4:
      return `linear-gradient(135.39deg, ${theme.colors.beige.sand} 1.32%, ${theme.colors.white.floral} 98.29%)`;
    case 5:
      return `linear-gradient(135.39deg, ${theme.colors.beige.sand} 1.32%, ${theme.colors.white.floral} 98.29%)`;
    default:
      return theme.colors.white;
  }
};

export const ExtendedBlock = styled(Block)<ExtendedBlockProps>`
  background: ${({ variant }) => switchBackgroundGradient(variant)};
  margin-top: -50px;
  padding: 60px 20px 40px 20px;

  ${media.desktop`
    margin-top: -60px;
    padding: 78px 50px 73px 50px;
  `}
`;

type ExtendedBlockProps = {
  variant: number;
};

export const ExtendedHeading = styled(Heading)`
  font-size: 18vw;
  text-transform: uppercase;
  line-height: 90%;
  letter-spacing: -0.07em;
  font-weight: 400;

  ${media.tablet`
    font-size: 15vw;
  `}

  ${media.desktop`
    font-size: 11.5vw;
    line-height: 80%;
  `}
`;

export const ExtendedLink = styled(StyledLink)`
  font-size: 14px;
  position: absolute;
  right: 20px;
  bottom: 40px;
  z-index: 2;
  text-align: right;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    bottom: 73px;
    right: 47px;
  `}
`;

export const ExtendedText = styled(RichText)`
  font-family: ${({ theme }) => theme.fonts.recklessNeue};
  font-size: 16px;
  line-height: 110%;
  position: absolute;
  bottom: 92px;
  right: 20px;
  text-align: left;
  width: 57vw;
  max-width: 480px;

  ${media.desktop`
    font-size: 28px;
    display: flex;
    flex-direction: column;
    bottom: 127px;
    right: 49px;
  `}
`;
