import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { StyledLink } from 'common/interaction';
import { Block, FocalPointImage } from 'common/layout';
import { Text, Heading } from 'common/typography';

export const MaskedImage = styled(FocalPointImage)`
  width: 100%;
  height: 100%;
  border-radius: 2px;
  object-fit: cover;
`;

export const ExtendedBlock = styled(Block)<ExtendedBlockProps>`
  position: relative;
  background-color: ${(props) => props.theme.colors.beige.dark};
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${({ $overlayOpacity }) =>
    typeof $overlayOpacity === 'number' &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: ${$overlayOpacity};
        transition: opacity 0.6s ease;
      }
    `}
`;

type ExtendedBlockProps = {
  $overlayOpacity?: number;
};

export const TextContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  z-index: 1;

  ${media.desktop`
    position: absolute;
    top: 50%;
    left: 70%;
    min-height: 25vh;
    transform: translate(-50%, -50%);
  `}
`;

export const ExtendedHeading = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.jvSignature};
  font-size: 12.5vw;
  line-height: 50%;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 45px;
  font-weight: 100;

  ${media.desktop`
    margin-bottom: 45px;
    font-size: 8vw;
  `}
`;

export const ExtendedText = styled(Text)`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${(props) => props.theme.colors.white};
  font-size: 6.25vw;
  line-height: 75%;
  margin-bottom: 25px;
  transition: font-variation-settings 0.8s;

  ${media.desktop`
    text-indent: 10px;
    margin-bottom: 45px;
    font-size: 4vw;
  `}
`;

export const CallToActionLink = styled(StyledLink)`
  font-family: ${(props) => props.theme.fonts.favoritPro};
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    align-self: flex-end;
  `}
`;
