import type * as i from 'types';
import * as React from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import { useWindowHeight } from '@react-hook/window-size/throttled';

import { FooterMenu, HeaderMenu } from 'common/layout';
import { BlurredCirclesBlock } from 'modules/blocks';

import { VideoHeader } from './VideoHeader';
import { VideoIntro } from './VideoHeader/VideoIntro';
import { VideoHeaderContainer } from './styled';

export const PageWithVideoHeader: React.FC<PageWithVideoHeaderProps> = ({
  children,
  headerLocations,
  navigation,
  videoHeader,
}) => {
  const [showIntro, setShowIntro] = React.useState(true);
  const [logoVisible, setLogoVisible] = React.useState(!videoHeader);
  const scrollY = useScrollPosition(30);
  const screenHeight = useWindowHeight();

  React.useEffect(() => {
    setLogoVisible(!videoHeader);
  }, [videoHeader]);

  React.useEffect(() => {
    if (!videoHeader) return;

    if (scrollY < screenHeight / 2 && logoVisible) {
      setLogoVisible(false);
    } else if (scrollY > screenHeight / 2 && !logoVisible) {
      setLogoVisible(true);
    }
  }, [scrollY, screenHeight, videoHeader]);

  return (
    <>
      <VideoIntro {...{ showIntro, setShowIntro }} />
      <VideoHeaderContainer $showIntro={showIntro}>
        {videoHeader && <VideoHeader {...videoHeader.fields} shouldAnimate={showIntro} />}
        <HeaderMenu
          menuItems={navigation.fields.headerNavigationItems}
          headerLocations={headerLocations}
          shouldAnimate={showIntro}
          hasFixedVideoHeader={!!videoHeader}
          logoVisible={logoVisible}
        />
        {children}
        <BlurredCirclesBlock>
          <FooterMenu navigation={navigation.fields} />
        </BlurredCirclesBlock>
      </VideoHeaderContainer>
    </>
  );
};

type PageWithVideoHeaderProps = {
  children: React.ReactNode;
  videoHeader: i.TypeComponentVideoHeader;
  headerLocations: i.HeaderLocation[];
  navigation: i.TypeNavigationList;
};
