import { motion } from 'framer-motion';
import styled from 'styled-components';

import RocycleLogo from 'vectors/internal/rocycle-logo.svg';

export const Container = styled(motion.div)<ContainerProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

type ContainerProps = {
  $isHidden: boolean;
};

export const StyledRocycleLogo = styled(RocycleLogo)`
  color: ${({ theme }) => theme.colors.black};
`;
