import type * as i from 'types';
import * as React from 'react';

import { isServer } from 'services';
import { useDevice } from 'services/hooks';
import { VideoBlock, ImageBlock } from 'modules/blocks';

export const VideoHeader: React.FC<Props> = ({ video, videoMobile, image, shouldAnimate }) => {
  const { isMobile, determined } = useDevice();
  const [videoVisible, setVideoVisible] = React.useState(true);

  React.useEffect(() => {
    if (isServer) return;

    const onScroll = () => {
      if (document.documentElement.scrollTop > window.innerHeight && videoVisible) {
        setVideoVisible(false);
      } else {
        setVideoVisible(true);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if (!determined) return null;

  if (isMobile) {
    if (videoMobile) {
      return (
        <VideoBlock
          source={videoMobile}
          shouldAnimate={shouldAnimate}
          videoVisible={videoVisible}
        />
      );
    }

    if (!videoMobile && image) {
      return (
        <ImageBlock
          image={image.fields.focalPoint.fields}
          loading="eager"
          style={{
            height: '93.5vh',
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
          }}
        />
      );
    }
  }

  if (video) {
    return <VideoBlock source={video} shouldAnimate={shouldAnimate} videoVisible={videoVisible} />;
  }

  return (
    <ImageBlock
      image={image?.fields.focalPoint.fields}
      loading="eager"
      style={{
        height: '93.5vh',
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
      }}
    />
  );
};

type Props = i.TypeComponentVideoHeaderFields;
