import type * as i from 'types';

import { FooterMenu, HeaderMenu, Split } from 'common/layout';
import {
  BlurredCirclesBlock,
  ContentTitleBlock,
  ImageBlock,
  PageBlurredCirclesBlock,
} from 'modules/blocks';

export const PageWithImageSideHeader: React.FC<PageWithImageHeaderProps> = ({
  children,
  headerLocations,
  navigation,
  headerImage,
  isFixedLayout = false,
  title,
}) => {
  return (
    <>
      <HeaderMenu
        menuItems={navigation.fields.headerNavigationItems}
        headerLocations={headerLocations}
      />
      <Split.Container>
        <Split.Content $first $fixedOnDesktop>
          {headerImage ? (
            <ImageBlock image={headerImage} />
          ) : (
            <PageBlurredCirclesBlock title={title} />
          )}
        </Split.Content>
        <Split.Content $centerContent={isFixedLayout}>
          {headerImage && <ContentTitleBlock title={title} />}
          {children}
        </Split.Content>
      </Split.Container>
      {!isFixedLayout && (
        <BlurredCirclesBlock>
          <FooterMenu navigation={navigation.fields} />
        </BlurredCirclesBlock>
      )}
    </>
  );
};

type PageWithImageHeaderProps = {
  children: React.ReactNode;
  headerLocations: i.HeaderLocation[];
  navigation: i.TypeNavigationList;
  title: string;
  headerImage?: i.TypeImageWithFocalPointFields;
  isFixedLayout?: boolean;
};
