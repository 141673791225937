import type * as i from 'types';
import * as React from 'react';

import { FooterMenu, HeaderMenu } from 'common/layout';
import { BlurredCirclesBlock } from 'modules/blocks';

import { HeaderImageTop } from './HeaderImageTop';

export const PageWithImageTopHeader: React.FC<PageWithImageHeaderProps> = ({
  children,
  headerLocations,
  imageHeader,
  navigation,
}) => {
  return (
    <>
      <HeaderMenu
        menuItems={navigation.fields.headerNavigationItems}
        headerLocations={headerLocations}
      />
      <HeaderImageTop header={imageHeader} />
      {children}
      <BlurredCirclesBlock>
        <FooterMenu navigation={navigation.fields} />
      </BlurredCirclesBlock>
    </>
  );
};

type PageWithImageHeaderProps = {
  children: React.ReactNode;
  imageHeader: i.TypeComponentVideoHeader;
  headerLocations: i.HeaderLocation[];
  navigation: i.TypeNavigationList;
};
