import type * as i from 'types';
import * as React from 'react';

import { ExtendedBlock, ExtendedHeading, ExtendedLink, ExtendedText } from './styled';

const CallToActionText: React.FC<Props> = ({ title, description, callToActionText, url }) => {
  return (
    <ExtendedBlock variant={1}>
      <ExtendedHeading margin="0">{title}</ExtendedHeading>
      {description && <ExtendedText>{description}</ExtendedText>}
      <ExtendedLink href={url}>{callToActionText} -&gt;</ExtendedLink>
    </ExtendedBlock>
  );
};

export type Props = i.TypeComponentCallToActionTextFields;

export default CallToActionText;
