import type * as i from 'types';
import * as React from 'react';

import { useWithComponentAnimation } from 'hooks';

import {
  ExtendedBlock,
  ExtendedHeading,
  HeadingSpan,
  TextContainer,
  CallToActionLink,
  RecruitmentBlockImage,
} from './styled';

const CallToActionTextRightLarge: React.FC<Props> = ({
  callToActionText,
  title,
  url,
  transparentBackground,
  ...props
}) => {
  const body = props.body.fields;
  const { imageAnimation, backgroundAnimation } = useWithComponentAnimation({
    background: {
      enabled: body.withAnimatedBackgroundColor,
    },
    image: {
      enabled: body.withAnimation,
      duration: 0.6,
      initialY: 60,
    },
  });

  return (
    <ExtendedBlock $transparentBackground={transparentBackground}>
      <RecruitmentBlockImage
        withAnimation
        image={body.image.fields}
        {...(imageAnimation.enabled && imageAnimation.props)}
        onViewportEnter={backgroundAnimation.onViewportEnter}
        onViewportLeave={backgroundAnimation.onViewportLeave}
      />
      <TextContainer>
        <ExtendedHeading>
          {body.title}
          <HeadingSpan>{body.description}</HeadingSpan>
        </ExtendedHeading>
        <CallToActionLink href={url || '/'}>{callToActionText} -&gt;</CallToActionLink>
      </TextContainer>
    </ExtendedBlock>
  );
};

export type Props = i.CTAComponentLayoutRight & {
  transparentBackground?: boolean;
};

export default CallToActionTextRightLarge;
